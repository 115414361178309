import styles from "./SpeechPage.module.css";
import TomatoButton from "render/TomatoButton";
import {
  ResultReason,
  AudioConfig,
  SpeechRecognizer,
  SpeechConfig,
  CancellationReason,
} from "microsoft-cognitiveservices-speech-sdk";
import { useMemo, useEffect, useState, useRef, useCallback } from "react";

enum State {
  "running",
  "stopped",
}

export default function SpeechPage() {
  const recognizer = useMemo(() => {
    // TODO: prototype hack, these ofc needs to be handed out by authed backend ..
    const tokenObj = {
      authToken: "7618309d9b884c7ebb9d5765ff3e3b0f",
      region: "swedencentral",
    };
    const speechConfig = SpeechConfig.fromSubscription(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage = "en-US";
    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
    return new SpeechRecognizer(speechConfig, audioConfig);
  }, []);

  const [recognizing, setRecognizing] = useState(
    "Yo, hit the mike and start talkin ..."
  );
  const [recognized, setRecognized] = useState<string[]>([]);
  const [state, setState] = useState<State>(State.stopped);

  const recognizedRef = useRef<HTMLDivElement>(null);

  function autoScroll() {
    if (recognizedRef.current) {
      recognizedRef.current.scrollTop = recognizedRef.current.scrollHeight;
    }
  }

  const startStop = useCallback(() => {
    if (state === State.running) {
      recognizer.stopContinuousRecognitionAsync();
      setState(State.stopped);
    } else {
      setRecognizing("");
      setRecognized([]);
      recognizer.startContinuousRecognitionAsync();
      setState(State.running);
    }
  }, [recognizer, state]);

  const getSummary = useCallback(async () => {
    startStop();

    let text = recognized.join(" ");
    console.log("Text to summarize: ", text);

    setRecognized([...recognized, "Gottcha! summarizing ..."]);

    const response = await fetch("https://api.openai.com/v1/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 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",
      },
      body: JSON.stringify({
        model: "text-davinci-003",
        prompt:
          "Summarize the following text in max four sentences:\n\n" +
          text +
          "\n\n",
        temperature: 0,
        max_tokens: 100,
      }),
    });
    const resp: any = await response.json();
    setRecognizing("");
    setRecognized([...recognized, "", "", "Summary: " + resp.choices[0].text]);
  }, [recognized, startStop]);

  const getAnswer = useCallback(async () => {
    startStop();

    let text = recognized.join(" ");
    console.log("Text to summarize: ", text);

    setRecognized([...recognized, "Gottcha! answering that ..."]);

    const response = await fetch("https://api.openai.com/v1/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 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",
      },
      body: JSON.stringify({
        model: "text-davinci-003",
        prompt: text + "\n\n",
        temperature: 0,
        max_tokens: 400,
      }),
    });
    const resp: any = await response.json();
    setRecognizing("");
    setRecognized([...recognized, "", "", "Answere: " + resp.choices[0].text]);
  }, [recognized, startStop]);

  // Init
  useEffect(() => {
    console.log("running");
    recognizer.recognizing = (s, e) => {
      console.log(`RECOGNIZING: Text=${e.result.text}`);
      setRecognizing(e.result.text);
    };

    recognizer.recognized = (s, e) => {
      if (e.result.reason === ResultReason.RecognizedSpeech) {
        const txt = e.result.text;
        console.log(`RECOGNIZED: Text=${txt}`);
        setRecognizing(e.result.text);
        setRecognized([...recognized, txt]);
        autoScroll();

        // Check for voice commands
        if (txt.toLocaleLowerCase().startsWith("ok, stop transcribing")) {
          startStop();
        } else if (txt.toLocaleLowerCase().startsWith("ok, summarize")) {
          console.log("asking for summary!");
          getSummary().then((summary) => {
            console.log(summary);
          });
        } else if (txt.toLocaleLowerCase().startsWith("ok, answer")) {
          console.log("asking for answer!");
          getAnswer().then((answer) => {
            console.log(answer);
          });
        } else if (txt.toLocaleLowerCase().startsWith("ok, clear")) {
          setRecognized([]);
        }
      } else if (e.result.reason === ResultReason.NoMatch) {
        console.log("NOMATCH: Speech could not be recognized.");
      }
    };

    recognizer.canceled = (s, e) => {
      console.log(`CANCELED: Reason=${e.reason}`);

      if (e.reason === CancellationReason.Error) {
        console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
        console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
        console.log(
          "CANCELED: Did you set the speech resource key and region values?"
        );
      }

      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.sessionStopped = (s, e) => {
      console.log("\n    Session stopped event.");
      recognizer.stopContinuousRecognitionAsync();
    };
  }, [recognizer, recognized, startStop, getSummary]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <TomatoButton
          onClick={startStop}
          classes={state === State.running ? styles.running : styles.stopped}
        >
          🎙
        </TomatoButton>
      </div>
      <div className={styles.recognizingOuter}>
        <div className={styles.recognizing}>{recognizing}</div>
      </div>
      <div className={styles.recognized} ref={recognizedRef}>
        {recognized.map((r, index) => {
          return (
            <div className={styles.sentence} key={index}>
              {r}
            </div>
          );
        })}
      </div>
    </div>
  );
}
